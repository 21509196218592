import {reactive, toRefs,defineComponent,onBeforeMount,onMounted,getCurrentInstance,nextTick} from 'vue';
import T001CardUtil ,{IT001CardDataObj} from './t001CardUtil';
import i001 from '@/assets/order/001.png';
export default defineComponent({
    name:'PiTemplateCard',
    title:'PI模板',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IT001CardDataObj=reactive<IT001CardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/piTemplate')
            },
            //表单
            form:{
                customerBean:{},
                customerDeliveryBean:{},
                orderData:{
                    orderContentData:[],
                    sumInfo:{}
                },
                piBean:{}
            },
            //表单验证规则
            rules: {},
            otherParams:{
                logoImg:i001,
                fromTemplate:false//是否从template那里打开的预览模板（tmd，否则分批转运那里始终有内容显示）
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new T001CardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            proxy.dialogInst.options.title=engineInst.engineParams.title;
            if(res.dataInfo){
                dataObj.form=res.dataInfo;
                dataObj.form.orderData=JSON.parse(dataObj.form.orderData);
                // dataObj.form.orderData.sumInfo.sumUsdAmount=dataObj.form.orderData.sumInfo.sumUsdAmount.toFixed(2);
                dataObj.form.orderData.sumInfo.sumUsdBigAmount=utils.UtilPub.toEnBigAmount(dataObj.form.orderData.sumInfo.sumUsdAmount);
                if(!res.dataInfo.customerDeliveryBean)dataObj.form.customerDeliveryBean={};
            }else{//tmd,否则在pi模板列表查看的时候要报错
                dataObj.otherParams.fromTemplate=true;
                dataObj.form={
                    customerBean:{},
                    customerDeliveryBean:{},
                    orderData:{
                        orderContentData:[],
                        sumInfo:{}
                    },
                    piBean:{}
                }
            }
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const setParam=(inst:any)=>{
            //from===PiCard PiTemplateList
            return{code:inst.engineParams.code,templateId:inst.engineParams.templateId,from:'t001',piId:inst.engineParams.piId};
        }
        const print=()=>{
            // let dialogContent:any = (document.getElementById('printContent') as any).innerHTML;
            // let printWindow :any= window.open('', '_blank');
            // printWindow.document.open();
            // printWindow.document.write('<html><head><title>打印</title></head><body>');
            // printWindow.document.write(dialogContent);
            // printWindow.document.write('</body></html>');
            // printWindow.document.close();
            // printWindow.print();

            let printContent:any = (document.getElementById('printContent') as any).innerHTML;
            let printContentContainer = document.createElement('div');
            printContentContainer.innerHTML = printContent;

            let printWindow :any= window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write('<html><head><title>打印</title>');
            printWindow.document.write('<link rel="stylesheet" type="text/css" href="t001.css"></head><body>');
            printWindow.document.write(printContentContainer.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();

            printWindow.print();
        }

        const printbak=()=>{
            let dialogContent:any = (document.getElementById('my-page') as any).innerHTML;
            let printWindow :any= window.open('', '_blank');
            printWindow.document.open();
            printWindow.document.write('<html><head><title>打印</title></head><body>');
            printWindow.document.write(dialogContent);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,beforeSaveHandler,setParam,print
         
        }
    }
});